export type ResponseType<T> = {
    data?: T
    isError: boolean
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logApiError = (message: string, error: any) => {
    console.error(message)
    if (error.response) {
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
    } else if (error.request) {
        console.error(error.request)
    } else {
        console.error(error.message)
    }
    console.error(error.config)
}

export { logApiError }
