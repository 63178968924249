import React, { useEffect, useState } from 'react'
import { Box, createTheme, CssBaseline, Link, responsiveFontSizes, ThemeProvider, Typography } from '@mui/material'
import { AppService } from './services'
import logoNodi from './logo.svg'

function App() {
    const theme = createTheme({ palette: { mode: 'dark' } })

    const [logo, setLogo] = useState<string>('')

    useEffect(() => {
        const getLogo = async () => {
            const result = await AppService.getInstance().getAppList()
            if (result.isError) { return }

            if (result.data?.apps.length !== 0) {
                setLogo(result.data?.apps[0].logo || 'no logo')
                console.log(result.data?.apps[0].logo)
            }
        }

        getLogo()
    }, [])

    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <CssBaseline enableColorScheme>
                <Box sx={{
                    padding: '16px',
                    textAlign: 'center',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '128px',
                }}>
                    <img src={logoNodi} style={{ height: '40vmin' }} alt="logo"/>
                    <Typography variant={'h4'}>
                        {
                            'The store of nod.i is still in construction, you can go to '
                        }
                        <Link
                            href={'https://nod-i.com'}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                        >
                            Nod.i
                        </Link>
                        {
                            ' for more information on it.'
                        }
                    </Typography>
                </Box>
            </CssBaseline>
        </ThemeProvider>
    )
}

export default App
