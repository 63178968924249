import { AppDetailsResponse, AppInstallFileResponse, AppListResponse } from './dto'
import ApiService from '../ApiService'
import { logApiError, ResponseType } from '../utils'

class AppService {
    private static instance: AppService

    private constructor() {
        // private constructor to prevent instantiation
    }

    // singleton getter
    public static getInstance(): AppService {
        if (!AppService.instance) {
            AppService.instance = new AppService()
        }

        return AppService.instance
    }

    private getUrl(end?: string): string {
        return `/app/${end || ''}`
    }

    public async getAppList(): Promise<ResponseType<AppListResponse>> {
        try {
            const result = await ApiService.getInstance().get<AppListResponse>(this.getUrl('all'))
            return {
                data: result.data,
                isError: false,
            }
        } catch (error) {
            logApiError('Error while getting all the application available', error)
            return { isError: true }
        }
    }

    public async getAppDetails(id: string): Promise<ResponseType<AppDetailsResponse>> {
        try {
            const result = await ApiService.getInstance().get<AppDetailsResponse>(this.getUrl(id))
            return {
                data: result.data,
                isError: false,
            }
        } catch (error) {
            logApiError('Error while getting the details of ' + id, error)
            return { isError: true }
        }
    }

    public async getAppInstaller(id: string): Promise<ResponseType<AppInstallFileResponse>> {
        try {
            const result = await ApiService.getInstance().get<AppInstallFileResponse>(this.getUrl('install/' + id))
            return {
                data: result.data,
                isError: false,
            }
        } catch (error) {
            logApiError('Error while getting the installer of ' + id, error)
            return { isError: true }
        }
    }
}

export { AppService }
