import axios, { AxiosPromise } from 'axios'
import { API } from '../utils'

// axios.interceptors.request.use(request => {
//   console.log('Starting Request', JSON.stringify(request, null, 2))
//   return request
// })

// axios.interceptors.response.use(response => {
//   console.log('Response:', JSON.stringify(response, null, 2))
//   return response
// })

class ApiService {
    // singleton constructor
    private static instance: ApiService

    private constructor() {
        // private constructor to prevent instantiation
    }

    // singleton getter
    public static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService()
        }
        return ApiService.instance
    }

    // build headers from tokens
    private buildHeaders(): Record<string, string> {
        const headers: Record<string, string> = {}

        headers.Accept = 'application/json'
        headers['Content-Type'] = 'application/json'
        return headers
    }

    private buildUrl(endpoint: string): string {
        return API + endpoint
    }

    /**
     * Send a GET HTTP request.
     * @param endpoint Endpoint to make the request.
     * @returns Answer promise of the request. Contains ResponseType.
     */
    public get<Response>(endpoint: string): AxiosPromise<Response> {
        return axios.get<Response>(this.buildUrl(endpoint), { headers: this.buildHeaders() })
    }

    /**
     * Send a POST HTTP request.
     * @param endpoint Endpoint to make the request.
     * @param body Body to send in the request.
     * @returns Answer promise of the request. Contains ResponseType.
     */
    public post<Request, Response>(endpoint: string, body: Request): AxiosPromise<Response> {
        return axios.post<Response>(this.buildUrl(endpoint), body, { headers: this.buildHeaders() })
    }

    /**
     * Send a PUT HTTP request.
     * @param endpoint Endpoint to make the request.
     * @param body Body to send in the request.
     * @returns Answer promise of the request. Contains ResponseType.
     */
    public put<Request, Response>(endpoint: string, body: Request): AxiosPromise<Response> {
        return axios.put<Response>(this.buildUrl(endpoint), body, { headers: this.buildHeaders() })
    }

    /**
     * Send a PATCH HTTP request.
     * @param endpoint Endpoint to make the request.
     * @param body Body to send in the request.
     * @returns Answer promise of the request. Contains ResponseType.
     */
    public patch<Request, Response>(endpoint: string, body: Request): AxiosPromise<Response> {
        return axios.patch<Response>(this.buildUrl(endpoint), body, { headers: this.buildHeaders() })
    }

    /**
     * Send a DELETE HTTP request.
     * @param endpoint Endpoint to make the request.
     * @returns Answer promise of the request. Contains ResponseType.
     */
    public delete(endpoint: string): AxiosPromise {
        return axios.delete(this.buildUrl(endpoint), { headers: this.buildHeaders() })
    }
}

export default ApiService
